@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

.book{
    color: white;
    text-decoration: transparent;
    animation: 0.5s ease-out 0s 1 slideInFromLeft;
}
@keyframes book {
    from {color: white;}
    to {color: #36A9E1;}
}

.book:hover {
    color: #36A9E1;
    text-decoration: none;
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
}

.hero {
    background-image: url("../../assets/services051.gif");
    background-position-y: center;
    padding: 100px 10px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 85vh;
    background-attachment: fixed;
}



.hero p {
    color: #fff;
    font-size: 25px;
    animation: 0.5s ease-out 0s 1 slideInFromRight;
}

.hero h1 {
    color: #fff;
    font-size: 100px;
    line-height: 90px;
    animation: 0.5s ease-out 0s 1 slideInFromLeft;
}

@media(max-width: 1024px) {
    .hero {
        background-image: url("../../assets/services051.gif");
        padding: 30px 10px 0px 10px;
        background-position: center;
        min-height: 50vh;
    }

    .hero p {
        color: #fff;
        font-size: 25px;
        animation: 0.5s ease-out 0s 1 slideInFromRight;
    }
    
    .hero h1 {
        color: #fff;
        font-size: 80px;
        line-height: 90px;
        animation: 0.5s ease-out 0s 1 slideInFromLeft;
    }
}

@media(max-width: 768px) {
    .hero {
        background-image: url("../../assets/services051.gif");
        padding: 30px 10px 0px 10px;
        background-position: center;
        min-height: 60vh;
    }

    .hero p {
        color: #fff;
        font-size: 18px;
        animation: 0.5s ease-out 0s 1 slideInFromRight;
    }
    
    .hero h1 {
        color: #fff;
        font-size: 50px;
        line-height: 50px;
        animation: 0.5s ease-out 0s 1 slideInFromLeft;
    }
}
