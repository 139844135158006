.clients{
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    overflow-x: scroll;
}
.clients img{
    filter: grayscale(100%);
    cursor: pointer;
    padding: .5rem;
    margin: 1rem 1rem 0rem;
}

.clients img:hover{
    filter: grayscale(0);
}

@media(max-width: 768px) {
.clients{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow-x: scroll;
}
.clients img{
    filter: none;
    padding: .5rem;
    margin: 1rem 1rem 0rem;
}
.clients img:hover{
    filter: none;
}
}
 