.cta{
background: #E6F9FF url() no-repeat;
background-size: auto;
background-position: right center;
border-radius: 45px;
position: relative;
padding:4rem  3rem;
margin: 6rem 0;
}

.cta3 {
    background: #E6F9FF url(../../assets/clipart2.png) no-repeat;
    background-size: auto;
    background-position: right center;
}

.cta2 {
    background: #E6F9FF url(../../assets/clipart1.png) no-repeat;
    background-size: auto;
    background-position: right center;
}

.cta1 {
    background: #E6F9FF url(../../assets/logofooter.png) no-repeat;
    background-size: contain;
    background-position: right center;
}

.cta img{
margin-top: -100px;
    position: absolute;
}


@media(max-width: 960px) {
    
    .cta{
        padding:2rem;
        text-align: center;
        margin: 4rem 1rem;
        }

    .cta2{
        position: relative;
        background: #E6F9FF no-repeat;
        background-size: auto;
        background-position: right center;

    }

    .cta2::before {
        content: "";
        background: #E6F9FF url(../../assets/clipart1.png) no-repeat;
        background-size: auto;
        background-position: right center;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.3;

    }

    .cta3{
        position: relative;
        background: #E6F9FF no-repeat;
        background-size: auto;
        background-position: right center;

    }

    .cta3::before {
        content: "";
        background: #E6F9FF url(../../assets/clipart2.png) no-repeat;
        background-size: auto;
        background-position: right center;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.3;

    }

    .cta1{
        position: relative;
        background: #E6F9FF no-repeat;
        background-size: contain;
        background-position: right center;

    }

    .cta1::before {
        content: "";
        background: #E6F9FF url(../../assets/logofooter.png) no-repeat;
        background-size: contain;
        background-position: right center;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.3;

    }

    h3, p {
        position: relative;
    }
}
