@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

:root {
    --font-base: 'Space Grotesk', sans-serif;
    --color-text: #01083A;
    --color-highlight: #36A9E1;
    --color-dark: #282828;
    --color-grey: #F3F3F3;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

* {
    font-family: var(--font-base);
}

body {
    background-image: url("./assets/body2.png");
    animation: moveIt 2s infinite;
    background-repeat: repeat-y;
    background-size: cover;
}

.section-padding {
    margin: 130px 0;
}

@media(max-width: 768px) {
    .section-padding {
        margin: 50px 0;
    }  
}


h1,
h2,
h3,
h4 {
    color: var(--color-text);
    font-weight: 500;
    animation: 1s ease-out 0s 1 slideInFromLeft;
}

h2 {

    font-size: 40px;
    line-height: 51px;
}

h3 {
    font-size: 30px;
    line-height: 38px;
}

h4 {
    font-size: 20px;
    line-height: 26px;
}

.title {
    font-size: 60px;
    line-height: 77px;
}

@media(max-width: 768px) {
    .title {
        font-size: 43px;
        line-height: 55px;
    }
}


ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

input[type="email"],
input[type="text"] {
    border-radius: 14px;
    padding: 15px;
}

.btn-positivus {
    padding: 15px 35px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #191A23;
    border-radius: 14px;
    border: none;
    color: #fff;
    animation: 1s ease-out 0s 1 slideInFromLeft;
}

.btn-positivus2 {
    padding: 10px 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    background: #404148;
    border-radius: 14px;
    border: none;
    color: #fff;
    animation: 1s ease-out 0s 1 slideInFromLeft;
}

.btn-positivus:hover {
    transform: scale(1.1);
    background: var(--color-highlight);
    color: var(--color-dark);

}

.btn-positivus2:hover {
    transform: scale(1.03);
    background: var(--color-highlight);
    color: var(--color-dark);

}

@media(max-width: 768px) {
    .btn-positivus {
        padding: 15px;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
    }
}

p {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
}